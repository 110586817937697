/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  
  // Load Events
$(document).ready(UTIL.loadEvents);

  // Display the mobile menu when clicking on the hamburger

$('#nav-trigger').click(function() {
  if ($('#nav-trigger').prop('checked')) {
    $('#nav-primary').slideDown();
    $('label[for="nav-trigger"]').addClass('x');
  } else {
    $('#nav-primary').slideUp();
    $('label[for="nav-trigger"]').removeClass('x');
  }
});

$('.menu-visit').children('a').click(function(e) {
  if ( $(this).children('.fa-chevron-right').is(':visible') ) {
    e.preventDefault();
    if ( $(this).siblings('.sub-menu').is(':hidden') ) {
      $(this).siblings('.sub-menu').slideDown();
      $(this).children('.fa-chevron-right').rotate(90);
    } else {
      $(this).siblings('.sub-menu').slideUp();
      $(this).children('.fa-chevron-right').rotate(0);
    }
  }
});

$('.menu-paso').children('a').click(function(e) {
  if ( $(this).children('.fa-chevron-right').is(':visible') ) {
    e.preventDefault();
    if ( $(this).siblings('.sub-menu').is(':hidden') ) {
      $(this).siblings('.sub-menu').slideDown();
      $(this).children('.fa-chevron-right').rotate(90);
    } else {
      $(this).siblings('.sub-menu').slideUp();
      $(this).children('.fa-chevron-right').rotate(0);
    }
  }
  
});

$('#site-search-icon').click(function() {
  $('#search').show();
  $('.main-menu').addClass('pr220');
  $(this).hide();
});

$('#site-search-button').click(function() {
  if ( $('.mobile').is(':visible')) {
    $('#search').hide();
    $('#site-search-icon').show();
    $('.main-menu').removeClass('pr220');
  } else {
    $('#search').submit();
  }
});

$('#searchbox').focus(function() {
  $('#nav-primary').animate({
        scrollTop: 250
    }, 200);
  $('#searchbox').val(' ');
  $('#searchbox').val('');
});


})(jQuery); // Fully reference jQuery after this point.

jQuery.fn.rotate = function(degrees) {
    jQuery(this).css({'-webkit-transform' : 'rotate('+ degrees +'deg)',
                 '-moz-transform' : 'rotate('+ degrees +'deg)',
                 '-ms-transform' : 'rotate('+ degrees +'deg)',
                 'transform' : 'rotate('+ degrees +'deg)'});
    return jQuery(this);
};

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
  var ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  
  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  
  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  
  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

function get_more_results() {
  var offset = jQuery('.content-container').find('article').length;
  var data = {
    'action': 'get_more_results',
    'offset' : offset
  };
  console.log(offset);
  jQuery.post( theme_ajax.url, data, function( data ){
    jQuery('.content-container').append(data);
  });
}
